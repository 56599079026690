import React, { useState, useEffect } from "react"
import { Button, Form, FormGroup, Label, Input } from "reactstrap"

const emailRegex = RegExp(
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
)

export default function newsletter() {
  const INITAIL_ERRORS = {
    email: "",
    exists: ""
  }
  const [email, setEmail] = useState("")
  const [errors, setErrors] = useState(INITAIL_ERRORS)
  const [success, setSuccess] = useState("")

  const handleChange = ({ target: { value } }) => {
    if (emailRegex.test(value)) {
      setEmail(value)
      setErrors({ ...errors, email: "" })
    } else {
      setEmail("")
      setErrors({ ...errors, email: "Invalid Email!" })
    }
  }
  const subscribe = async e => {
    e.preventDefault()
    try {
      const request = await fetch(
        "https://cors-anywhere.herokuapp.com/https://3mnziud8d3.execute-api.us-west-2.amazonaws.com/TestDeployment/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "x-api-key": "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
          },
          body: JSON.stringify({
            email,
            url: window.location.origin
          })
        }
      )
      if (request.status !== 200) {
        throw Error(`Unexpected error occurred: ${request.status}`)
      }
      const response = await request.json()
      if (!response.errorMessage) {
        setSuccess(true)
        setErrors({ ...errors, exists: "" })
      } else if (response.errorMessage) {
        setSuccess(false)
        setErrors({ ...errors, exists: "This user already exists!" })
      }
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <div>
      <Form onSubmit={subscribe}>
        <FormGroup>
          <Label for="email">Email Address:</Label>
          <Input type="text" name="email" id="email" onChange={handleChange} placeholder="example@domain.com" />
          {errors.email.length > 0 ? <p>{errors.email}</p> : null}
        </FormGroup>
        <Button type="submit" disabled={errors.email.length !== 0}>
          Send
        </Button>
      </Form>
      {success ? <p>Success</p> : <p>{errors.exists}</p>}
    </div>
  )
}
